<template>
  <br-component component="main">
    <template #default="{ page, component }">
      <BranchDetails :page="page" :component="component" :branch="branch" v-if="branch && setBranchMeta(page)" />
    </template>
  </br-component>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import useBranches from "../../composables/useBranches";
import { useHandleError } from "../../composables/useHandleError";
import { ref } from "vue";
import type { Maybe } from "@vue-storefront/unified-data-model";
import { Page } from "@bloomreach/spa-sdk";
import { Branch } from "mkm-types";

const meta = ref<{ name: string; hid: string; content: any }[]>([]);
const branch = ref<Maybe<Branch[]>>(null);

const route = useRoute();
const { fetchBranchBySlug } = useBranches();

const setBranchMeta = (page: Page) => {
  meta.value = [
    {
      name: "description",
      hid: "description",
      content: page
        .getDocument<any>()
        ?.getData()
        .content?.value.replace(/<\/?[^>]+(>|$)/g, ""),
    },
  ];

  return true;
};

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
  meta: meta,
});

try {
  branch.value = await fetchBranchBySlug(route.params.branch as string);
} catch (error) {
  if (!branch.value) {
    useHandleError({
      status: 404,
      message: (error as any).message,
      statusMessage: (error as any).statusMessage,
      fatal: false,
    });
  }
}
</script>
